<template>
  <div id="events">
    <h1>{{ $t("EVENT.EVENTS") }}</h1>
    <b-table
      v-if="events.length > 0"
      striped
      hover
      :fields="$t('EVENT.FIELDS')"
      :items="events"
    >
      <template #cell(eventDate)="row">
        {{ formatDate(row.item.eventDate) }}
      </template>
      <template #cell(show_details)="row">
        <b-button
          size="sm"
          @click="
            $router.push({ name: 'Event Details', params: { id: row.item.id } })
          "
          class="mr-2 btn-primary"
        >
          {{ $t("COMMON.SHOW_DETAILS") }}
        </b-button>
        <b-button
          v-if="roles.includes('ROLE_ADMIN') && row.item.count === 0"
          size="sm"
          class="btn-success"
          @click="editEvent(row.item.id)"
          >Edit</b-button
        >
      </template>
    </b-table>
    <b-alert v-else class="mt-5" show>Currently There's no events</b-alert>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  created() {
    this.getEvents();
  },
  data() {
    return {
      fields: [
        "id",
        "eventName",
        "eventDate",
        "location",
        "show_details",
        "edit",
      ],
    };
  },
  methods: {
    ...mapActions({
      getEvents: "getEvents",
    }),
    formatDate(date) {
      const today = new Date(date);
      const yyyy = today.getFullYear();
      let mm = today.getMonth() + 1; // Months start at 0!
      let dd = today.getDate();

      if (dd < 10) dd = "0" + dd;
      if (mm < 10) mm = "0" + mm;

      const formattedToday = dd + "-" + mm + "-" + yyyy;

      return formattedToday;
    },
    editEvent(id) {
      this.$router.push({ name: "Edit Event", params: { id } });
    },
  },
  computed: {
    ...mapGetters({
      events: "events",
      currentUser: "currentUser",
      roles: "roles",
    }),
  },
};
</script>

<style lang="scss" scoped>
#events {
  background: #fff;
  padding: 20px;

  h1 {
    font-size: 20px;
    color: #3c4eba;
    font-weight: 500;
  }
}
</style>
